<script>
  import {
    loginJump
  } from "@/api/index.js"
  export default {

    data() {
      return {
        userInfo:{}
      }
    },
    methods:{
      pathTo(){
        if( this.userInfo){
          loginJump(this,this.userInfo)
        }
      }
    },
    created() {
     // 判断是否机构用户
     this.userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
    },
  };
</script>

<template>
  <div class="my-5 pt-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="text-center my-5">
            <h1 class="font-weight-bold text-error">
              4
              <span class="error-text">
                0
                <img src="@/assets/images/error-img.png" alt class="error-img" />
              </span> 4
            </h1>
            <h3 class="text-uppercase">抱歉，该页面没有找到</h3>
            <div class="mt-5 text-center">
              <el-button type="success" size="medium" @click="pathTo">返回主页</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
